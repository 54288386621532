<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    v-model="show"
    max-width="500px"
    height:400px
    scrollable
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card width="500px" v-else>
      <v-card-title
        height="100px"
        dark
        color="#7253cf"
        style="background-color: #7253cf; color: white"
      >
        <div class="d-flex">
          <img
            src="@/assets/DistrictCampaign/selectCampaignLogo.svg"
            height="85"
            width="100"
          />
        </div>
        <div class="ml-6 pt-2 dialogTitle-text">Select a Game</div>
        <v-spacer></v-spacer>
        <v-icon
          text
          size="27"
          color="white"
          style="cursor: pointer; margin-top: -80px; margin-right: -15px"
          @click="toggleSelectGameModal({ show: false })"
          >mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-0 py-0" style="max-height: 230px">
        <v-simple-table class="px-0">
          <template v-slot:default>
            <tbody>
              <tr
                class="tableRow"
                v-for="(item, i) in gameList"
                :key="i"
                @click="selectGame(item.id)"
              >
                <td class="text-center">
                  <v-icon color="#2C1963" v-if="selectedGameId === item.id"
                    >mdi-check</v-icon
                  >
                </td>
                <td class="text-center py-4" style="font-weight: 600">
                  <div style="font-size: 14px">
                    {{ item.game_date_str_2 }}
                  </div>
                 
                </td>
             <td class="text-center" style="font-weight: 700">
                  <v-chip :class="`${item.status.toUpperCase()}`"
                    >{{ item.status }} Game</v-chip
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  ADMIN_API_GAME_MANAGEMENT_LIST,
  ADMIN_GAMES_GET_DETAIL
} from "@/constants/APIUrls";
export default {
  name: "SelectGameModal",
  data() {
    return {
      loading: false,
      gameList: [],
      selectedGameId: null,
      school: null,
      goldmineID:localStorage.getItem("goldmineid")
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "gameManagement/getSelectShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleSelectGameModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.selectGameModal.type;
    },
    selectFlag() {
      if (this.selectedGameId) return true;
      else return false;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeMoal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleSelectGameModal: "gameManagement/toggleSelectModal",
      toggleStoreDetail: "gameManagement/toggleGameModal",
    }),
    openModal() {
      this.getGameList();
    },
    closeMoal() {
      this.loading = false;
    },
     getGameDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.gamedtail = res.data.game_detail;
        this.goldMineId=this.gamedtail.goldmine_id;
       // localStorage.setItem("goldmineid",this.goldMineId)
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      localStorage.setItem("gameID",this.$route.query.game_id)
      Axios.request_GET(
        ADMIN_GAMES_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getGameList() {
      this.loading = true;
      const successHandler = (res) => {
        this.gameList = res.data.switch_game_list;
        console.log(this.type);
        this.selectGame();
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      if (this.school) formData["school_id"] = this.school;
       formData["goldmine_id"] = this.goldMineId;
      Axios.request_GET(
        ADMIN_API_GAME_MANAGEMENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    selectGame(id) {
      if (this.selectedGameId) {
        if (id) {
          this.selectedGameId = id;
          this.selectGameHelper();
        }
      } else {
        if (this.$route.query.game_id) {
          this.selectedGameId = parseInt(this.$route.query.game_id);
          console.log(this.selectedGameId);
        } else {
          this.selectedGameId = this.gameList[0].id;
        }
        this.selectGameHelper();
      }
    },
    selectGameHelper() {
      let index = this.gameList.findIndex(
        (item) => item.id == this.selectedGameId
      );
      let game = this.gameList[index];

      let gameCount = this.gameList.length;
      let gameDetail = { ...game, gameCount };
      this.toggleStoreDetail({ detail: gameDetail });
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, game_id: this.selectedGameId },
      });
      this.toggleSelectGameModal({ show: false });
      this.$emit("reload");
      setTimeout(() => {
        this.$root.$refs.TicketInfoTable.getTicketInfo();
        this.$root.$refs.gameLayout.getGameDetail();
        this.$root.$refs.checkList.getChecklistData();
        this.$root.$refs.gameDashboardN.getNoteData();
        this.$root.$refs.gameDashboardVASH.getAssemblyData();
      }, 100);
    },
 
  },
  mounted() {
    if (this.$route.query.school) this.school = this.$route.query.school;
    // if(this.$route.query.game_id) this.selectedGameId = this.$route.query.game_id
    this.getGameDetail()
    this.getGameList();
   
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}
span.v-chip.ONGOING {
  margin-top: 10px;
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
}
span.v-chip.UPCOMING {
  margin-top: 10px;
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
}
span.v-chip.COMPLETED {
  margin-top: 10px;
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
}
span.v-chip.DRAFT {
  margin-top: 10px;
  border-radius: 10px;
}
tr.tableRow {
  cursor: pointer;
}
</style>
